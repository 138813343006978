import React from "react";
import QRCodeGen from "./webScreen/WebScreen";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from "./webScreen/adminLogin/Login";
import DataShow from "./webScreen/DataShow";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login></Login>} />
        <Route path="/generate-qr-code" element={<QRCodeGen></QRCodeGen>} />
        <Route path="/qr-codes" element={<DataShow/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
