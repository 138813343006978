import React, { useState } from 'react'
import qrgen from 'qrcode'
import './style.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@mui/material'

const QRCodeGen = () => {

    const navigate = useNavigate()


    const generateQrCode = async () => {
        const uniqueId = uuidv4()
        try {
            const qrImg = await qrgen.toDataURL(uniqueId)
            axios.post('https://qrapi.emedha.in/store-qr-code', {
                initialization,
                qrImg,
                uniqueId
            }).then((res) => {
                if (res.data.status === 200) {
                    alert('QR code generate successfully')
                    setInitialization([])
                } else {
                    alert('Member is already exist')
                }
            })
        }
        catch {
            alert('somthing error')
        }
    }

    const [initialization, setInitialization] = useState([{
        name: "",
        clubNume: "",
        mobile: ""
    }
    ])


    const handelInputField = (e, index) => {
        const values = [...initialization]
        values[index][e.target.name] = e.target.value

        setInitialization(values)
    }


    const generateForm = () => {
        dynamicInput()
    }

    const dynamicInput = () => {
        const _initialization = [...initialization]
        _initialization.push({
            name: "",
            clubNume: "",
            mobile: ""
        })
        setInitialization(_initialization)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }} >
            <div style={{ height: '43rem', width: '63rem', backgroundColor: 'white', display: 'flex', justifyContent: 'center', overflow: 'auto', paddingTop: 21, paddingBottom: 10 }} className='qr-con' >
                <div>
                    <h3 style={{ color: 'blue' }} >Generate QR Code</h3>
                    <div style={{ height: '3rem', width: '31rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                        <button onClick={() => generateQrCode()} style={{ cursor: 'pointer', width: '9rem', height: '2.1rem', backgroundColor: 'blue', color: 'white', border: 'none', borderRadius: '0.2rem' }} >Generate</button>
                        <button onClick={() => navigate('/qr-codes')} style={{ cursor: 'pointer', width: '9rem', height: '2.1rem', backgroundColor: 'blue', color: 'white', border: 'none', borderRadius: '0.2rem' }} >View</button>
                    </div>
                    <div style={{ width: '60rem', height: '20rem', justifyContent: 'space-between', alignItems: 'center' }} >

                        <Button onClick={() => generateForm()} className='addInputField' style={{ textDecoration: 'none', cursor: 'pointer', backgroundColor: 'rgb(53, 53, 111)', fontWeight: 'bold', height: '1.7rem', marginTop: '0.2rem' }} variant='contained' >Generate Form</Button>
                        <br />
                        <br />
                        <br />
                        {
                            initialization.map((data, index) => {
                                return (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 23 }} >

                                        <input name='name' placeholder='Full Name' style={{ width: '18rem', border: 'none', borderBottom: '2px solid gray', outline: 'none', paddingBottom: 10, paddingLeft: 1 }} onChange={(e) => handelInputField(e, index)} />
                                        <input name='mobile' placeholder='Mobile Number' style={{ width: '18rem', border: 'none', borderBottom: '2px solid gray', outline: 'none', paddingBottom: 10, paddingLeft: 1 }} onChange={(e) => handelInputField(e, index)} />
                                        <input name='clubName' placeholder='Club Name' style={{ width: '18rem', border: 'none', borderBottom: '2px solid gray', outline: 'none', paddingBottom: 0, paddingLeft: 1, paddingBottom: 10 }} onChange={(e) => handelInputField(e, index)} />
                                    </div>
                                )
                            })
                        }
                    </div>



                    <br />

                </div>
            </div>
        </div>
    )
}

export default QRCodeGen