import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'auto',
    color: theme.palette.text.secondary,
}));



export default function DataShow() {

    const navigate = useNavigate()

    const [qrs, setQrs] = React.useState([])

    const qrCode = () => {
        axios.get('https://qrapi.emedha.in/qr-codes-all').then((res) => {
            setQrs(res.data)
        })
    }

    React.useEffect(() => {
        qrCode()
    }, [])

    return (
        <Box sx={{ flexGrow: 1, p: 7 }}>

            <div style={{ height: '3rem', width: '31rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }} >
                <button onClick={() => navigate('/generate-qr-code')} style={{ cursor: 'pointer', width: '9rem', height: '2.1rem', backgroundColor: 'blue', color: 'white', border: 'none', borderRadius: '0.2rem' }} >Generate QR</button>
            </div>

            <Grid container spacing={2}>
                {
                    qrs.map((data) => {
                        return (
                            <Grid item xs={3}>
                                <Item>
                                    <a download href={data.qr} > <img src={data.qr} style={{ width: '100%' }} /></a>
                                    <div style={{backgroundColor:'blue', paddingLeft:10, paddingBottom:6}} >
                                        <Typography style={{ fontSize: 18, color: 'white' }} >{data.name}</Typography>
                                        <Typography style={{ fontSize: 12, color: 'white' }} >{data.mobile}</Typography>
                                        <Typography style={{ fontSize: 12, color: 'white' }} >{data.club_name}</Typography>
                                        <Typography style={{ fontSize: 12, color: 'white' }} >{data.memberId}</Typography>
                                    </div>
                                </Item>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    );
}