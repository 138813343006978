import React, { useState } from 'react'
import qrgen from 'qrcode'
import '../style.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Login = () => {


    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()




    const login = () => {
        axios.post('https://qrapi.emedha.in/admin-login', {
            mobile,
            password
        }).then((res) => {
            if (res.data.status === 200) {
                alert('Login Suceessfyll')
                navigate('/generate-qr-code')
            } else {
                alert('Please enter valid data')
            }
        })
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }} >
            <div style={{ height: '43rem', width: '63rem', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='qr-con' >
                <div>
                    <h3 style={{ color: 'blue' }} >Admin Login</h3>
                    <div style={{ width: '31rem', height: '20rem', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor:'blue' }} >
                        <div>
                            <input placeholder='Mobile Number' style={{ width: '100%', border: 'none', borderBottom: '2px solid gray', outline: 'none', paddingBottom: 10, paddingLeft: 1, marginBottom: 57 }} onChange={(e) => setMobile(e.target.value)} />
                            <input type='password' placeholder='Password' style={{ width: '100%', border: 'none', borderBottom: '2px solid gray', outline: 'none', paddingBottom: 0, paddingLeft: 1, paddingBottom: 10 }} onChange={(e) => setPassword(e.target.value)} />
                            <br/>
                            <br/>
                            <br/>
                            <button onClick={() => login()} style={{ cursor: 'pointer', width: '100%', height: '2.1rem', backgroundColor: 'white', color: 'blue', border: 'none', borderRadius: '0.2rem', fontWeight:'bold' }} >Login</button>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </div>
    )
}

export default Login